<template>
  <div class="border border-neutral-200 rounded-lg hover:shadow-md" :class="{'min-h-[365px]' : !isTextExpanded(review)}">
    <UiMaskedLink
      role="link"
      tabindex="0"
      :aria-label="review?.object.hausname"
      :href="review?.object?.url"
      :new-tab="true"
    >
      <div class="grid grid-cols-3 p-4 gap-3 min-h-[130px] lg:gap-8 xl:gap-0">
        <div class="w-full bg-white rounded-lg overflow-hidden h-[78px] w-[78px] lg:h-[85px] lg:w-[85px] xl:w-[95px] xl:h-[95px]">
          <img :src="review?.object.bilder_neu[0]" class="object-cover object-center h-full w-full" :alt="review?.object?.hausname">
        </div>
        <div class="col-span-2">
          <h3 class="text-sm lg:text-lg font-semibold truncate-multiline" :class="{ 'line-clamp-2': !isTextExpanded(review) }">
            {{ truncateText(review?.object.hausname, 54) }}
          </h3>
          <p class="text-sm text-gray-500">
            <span class="text-petrol-500">
              {{ review?.object.region }}
            </span>
            - {{ review?.object.ortsname }}
          </p>
          <p class="text-sm text-gray-500">
            {{ review?.object.wohnflaeche }}m² - {{ review?.object.personen }}
            <UiLanguagePlaceholder domain="SharedObjectCard" name="guests" />
          </p>
          <p v-if="review?.object.schlafzimmer > 0" class="text-sm text-gray-500">
            {{ review?.object.schlafzimmer }} <UiLanguagePlaceholder domain="SharedObjectCard" name="bedroom" />
          </p>
        </div>
      </div>
      <div class="grid grid-cols-2 px-4 pb-3">
        <div class="flex items-center">
          <SharedStars :width="14" :height="14" :rating-text="true" :rating="review?.object.bewertung_durchschnitt" />
        </div>
        <div class="text-right">
          <span class="text-lg font-bold text-gray-800">
            <UiLanguagePlaceholder domain="SharedObjectCard" name="from" />
            <span class="text-petrol-500 mx-1">{{ usePrices().totalPrice(review?.object.preis_min) }}</span>
          </span>
          <span class="text-sm text-gray-600">
            <UiLanguagePlaceholder domain="SharedObjectCard" name="night" />
          </span>
        </div>
      </div>
    </UiMaskedLink>
    <div class="border-t border-neutral-200 p-4">
      <div class="min-h-[108px]">
        <UiTextMultiLineCollapse class="text-sm leading-5" :height="80">
          <template #collapse>
            <p ref="textContent">
              „{{ review?.text }}“
            </p>
          </template>
          <template #inactive>
            <div v-if="shouldShowToggle" class="mt-2">
              <UiLanguagePlaceholder v-if="!isTextExpanded(review)" domain="RuesHeadlineLinkCollection" name="show_more" />
              <UiIcon
                src="/svg/icons/arrow-thin.svg"
                width="9"
                height="9"
                alt="Travanto"
                class="inline-block ml-1 rotate-90"
              />
            </div>
            <div v-else />
          </template>
          <template #active>
            <div v-if="shouldShowToggle" class="mt-2">
              <UiLanguagePlaceholder v-if="!isTextExpanded(review)" domain="RuesHeadlineLinkCollection" name="show_less" />
              <UiIcon
                src="/svg/icons/arrow-thin.svg"
                width="9"
                height="9"
                alt="Travanto"
                class="inline-block ml-1 rotate-270"
              />
            </div>
            <div v-else />
          </template>
        </UiTextMultiLineCollapse>
      </div>
      <div class="items-center mt-4">
        <p class="text-sm text-gray-700 font-bold">
          {{ review?.nickname }}
        </p>
        <p>
          <UiIcon src="/svg/icons/verifiziert-haken.svg" width="12" height="12" class="fill-green-500 inline-block" />
          <span class="ml-1 text-[12px] text-green-500">
            <UiLanguagePlaceholder domain="SharedObjectCard" name="verify_partner" />
          </span>
        </p>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
defineProps({
  review: {
    type: Object,
    required: true
  }
})
const expandedReviews = ref<string[]>([])

const isTextExpanded = (review: any) => {
  return expandedReviews.value.includes(review)
}

const textContent = ref<HTMLElement | null>(null)
const shouldShowToggle = ref(false)

const updateToggleState = () => {
  if (textContent.value) {
    const height = textContent.value.scrollHeight
    shouldShowToggle.value = height > 80
  }
}

onMounted(() => {
  updateToggleState()
  window.addEventListener('resize', updateToggleState)
})

onBeforeUnmount(() => {
  window.removeEventListener('resize', updateToggleState)
})
const truncateText = (text, maxLength) => {
  if (text.length <= maxLength) {
    return text
  }
  return text.substring(0, maxLength) + '...'
}
</script>
<style scoped>
.truncate-multiline {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
.line-clamp-2 {
  -webkit-line-clamp: 2;
}
</style>
